import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import {
  ModuleWithProviders,
  NgModule,
  makeEnvironmentProviders,
} from '@angular/core';

import { BackendConfig } from './config/backend.config';

@NgModule({
  declarations: [],
  exports: [],
  imports: [],
  providers: [provideHttpClient(withInterceptorsFromDi())],
})
export class BackendModule {
  static forRoot(config: BackendConfig): ModuleWithProviders<BackendModule> {
    return {
      ngModule: BackendModule,
      providers: [{ provide: BackendConfig, useValue: config }],
    };
  }
}

export function provideBackend(config: BackendConfig) {
  return makeEnvironmentProviders([
    provideHttpClient(withInterceptorsFromDi()),
    { provide: BackendConfig, useValue: config },
  ]);
}
