import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AccountService } from '@cf/temp/services';

export const authorizedGuard: CanActivateFn = () => {
  const router = inject(Router);
  const accountService = inject(AccountService);

  if (!!accountService.account.id) {
    void router.navigate(['/dashboard']);
    return false;
  }

  return true;
};
